import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import A from '~/components/elements/svg/A'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentSteps({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentSteps" className="section bg-site-light">
      <div className="container">
        <div className="lg:grid flex flex-col grid-cols-12 lg:items-center lg:mb-12 mb-8 gap-y-6">
          <Content className="content xl:children-strong:text-6xl lg:children-strong:text-5xl children-strong:text-4xl  xl:children-h2:text-5xl lg:children-h2:text-4xl children-h2:text-3xl children-strong:font-outline-site-base col-span-5">
            {fields.steps.title}
          </Content>
          <Content className="content col-span-7">{fields.steps.description}</Content>
        </div>

        <div className="sm:grid flex flex-col grid-cols-6 relative items-start">
          <div className="flex flex-col gap-y-4 relative z-10 xl:col-span-3 lg:col-span-4 col-span-5">
            {fields.steps.descriptions.map((description, index) => (
              <div className="p-8 bg-glass rounded-2xl flex sm:flex-row flex-col sm:items-center sm:gap-8 gap-4" key={index}>
                {fields.steps.useCounting && (
                  <span className="font-outline-site-base sm:text-8xl text-6xl font-headings font-bold">{`${index + 1}.`}</span>
                )}
                <Content className="content">{description.description}</Content>
              </div>
            ))}
          </div>

          <div className="xl:w-[120%] lg:w-[200%] md:w-[500%] sm:w-[700%] xl:-ml-[20%] lg:-ml-[75%] md:-ml-[200%] sm:-ml-[250%] -ml-4 sm:mr-0 -mr-4 sm:opacity-100 opacity-40 sm:relative absolute xl:col-span-3 lg:col-span-2 col-span-1">
            <LossePlaatjie maxWidth={1400} className="w-[800px] h-[600px] object-contain " src={fields.steps.image} />
          </div>

          <div className="col-span-3 xl:block hidden"></div>
          {fields.steps?.appointment?.link && (
            <div className="xl:col-span-3 lg:col-span-4 col-span-5 lg:mt-0 sm:-mt-6 mt-6 w-full">
              <div
                role="button"
                className="relative z-10 mx-auto xl:-mt-24 p-2 bg-white rounded-full flex items-center justify-between sm:w-[375px] w-[225px]"
              >
                <LosseLink className="content font-bold text-center w-full text-site-base-500" src={fields.steps.appointment.link} />
                <div className="rounded-full w-[50px] h-[50px] bg-site-base flex items-center justify-center flex-none">
                  {fields.steps.appointment.image ? (
                    <LossePlaatjie className="object-cover" maxWidth={100} src={fields.iframe.meetImage} />
                  ) : (
                    <A className="w-[70%] h-[70%] -mt-[6px]" />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
