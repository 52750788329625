import { LosseLink } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Case, Page_Flexcontent_Flex_Posts } from '~/graphql/types'

export default function CasesHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const firstCase = fields.highlightedCases[0] as Case
  const secondCase = fields.highlightedCases[1] as Case

  return (
    <section data-component="CasesHighlighted" className="relative section bg-gradient-to-t from-site-light via-site-light/90 to-white">
      <img loading="lazy" src="/static/highlighted-case-cube.png" alt="" className="absolute right-0 h-52 w-auto lg:hidden" />

      <div
        className="absolute -left-[20%] -rotate-[28deg] w-4/5 h-[600px] max-lg:opacity-50"
        style={{
          background: 'radial-gradient(50% 50.00% at 50% 50.00%, #44277C 0%, rgba(68, 39, 124, 0.22) 50%, rgba(68, 39, 124, 0.00) 100%)'
        }}
      ></div>
      <div className="relative container z-10">
        <Content className="content xl:children-strong:text-7xl lg:children-strong:text-6xl md:children-strong:text-5xl children-strong:text-3xl xl:children-h2:text-7xl md:children-h2:text-6xl children-h2:text-5xl children-strong:font-outline-site-base  content max-w-[75ch]">
          {fields.description}
        </Content>
        <div className="grid grid-cols-9 gap-x-2 sm:gap-x-4 lg:gap-x-8 max-lg:-mx-4">
          <div className="col-span-4 lg:col-span-3">
            {secondCase && (
              <div className="bg-white rounded-r-2xl sm:rounded-2xl shadow-md p-4 lg:p-8 mt-10 lg:mt-20">
                <Content className="max-sm:text-sm">{secondCase.info.description}</Content>
                <div className="flex justify-end mt-6">
                  <Content className="children-p:font-semibold pr-10">{secondCase.info.representative}</Content>
                </div>
              </div>
            )}
          </div>
          <div className="col-span-5 lg:col-span-4">
            <div className="bg-white rounded-l-2xl sm:rounded-2xl shadow-md p-4 lg:p-8">
              <Content className="max-sm:text-sm">{firstCase.info.description}</Content>
              <div className="flex justify-end mt-6">
                <Content className="children-p:font-semibold pr-10">{firstCase.info.representative}</Content>
              </div>
            </div>
            {fields.link && (
              <LosseLink
                className="btn btn-light block text-center max-sm:mx-auto max-sm:text-sm mt-10 lg:mt-12 max-sm:mr-2 lg:w-fit"
                to={fields.link.url}
              >
                {fields.link.title}
              </LosseLink>
            )}
          </div>
          <div className="hidden lg:flex lg:col-span-2">
            <img loading="lazy" src="/static/highlighted-case-cube.png" alt="" className="mt-24 -ml-20 w-full object-contain" />
          </div>
        </div>
      </div>
    </section>
  )
}
