import type { Post as PostProps, Maybe, RootQueryToPageConnection, Page_Flexcontent_Flex_RelatedPosts } from '~/graphql/types'
import Slider, { Slide } from '~/components/elements/Slider'
import { Autoplay, Navigation } from 'swiper/modules'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import Content from '~/components/elements/Content'
import { useState } from 'react'
import { LosseLink, useLosseLanguage } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Post from '../Posts/post-types/Post'

interface PostsRelatedBlogs extends Page_Flexcontent_Flex_RelatedPosts {
  subdata: {
    RelatedPostsSubQuery: Maybe<RootQueryToPageConnection>
  }
}

export default function PostsRelatedBlogs({ fields }: { fields: PostsRelatedBlogs }) {
  const initialPosts =
    fields?.relatedBlogsType === 'self_select'
      ? fields?.highlightedBlogs
      : fields?.subdata?.RelatedPostsSubQuery?.edges?.flatMap(({ node }) => node)
  const [posts] = useState(initialPosts)
  const [, locale] = useLosseLanguage()

  const _posts = posts?.filter((post) => post?.locale?.locale === locale)

  if (!_posts || _posts.length === 0) return null

  return (
    <section
      id={fields?.sectionId || ''}
      data-component="PostsRelatedBlogs"
      className="section lg:!py-40"
      style={{
        boxShadow: 'inset 0 0 20px 0 #DDD7E7'
      }}
    >
      <div className="container">
        <div className="grid grid-cols-12 gap-x-7">
          <div className="col-span-12 lg:col-span-8 2xl:col-span-9">
            <div className="flex justify-between items-end mb-5">
              <Content className="children-strong:font-outline-site-base children-h2:text-2xl xs:children-h2:text-3xl md:children-h2:text-5xl lg:children-h2:text-7xl">
                {fields.description}
              </Content>
              <div className="relative flex flex-col items-end z-20">
                {fields?.link?.url && (
                  <LosseLink className="block hover:underline mb-3 text-site-base" to={fields.link.url}>
                    {fields.link.title}
                  </LosseLink>
                )}

                <div className="flex">
                  <button className="posts-related-button-prev relative mr-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M24 12C24 9.62663 23.2962 7.30655 21.9776 5.33316C20.6591 3.35977 18.7849 1.8217 16.5922 0.913451C14.3995 0.00519901 11.9867 -0.232441 9.65891 0.230581C7.33114 0.693604 5.19295 1.83649 3.51472 3.51472C1.83649 5.19295 0.693597 7.33115 0.230575 9.65892C-0.232449 11.9867 0.00519117 14.3995 0.913444 16.5922C1.82169 18.7849 3.35976 20.6591 5.33315 21.9776C7.30654 23.2962 9.62662 24 12 24C15.1815 23.9966 18.2318 22.7312 20.4815 20.4815C22.7312 18.2318 23.9966 15.1815 24 12H24ZM2 12C2 10.0222 2.58649 8.08879 3.6853 6.4443C4.78412 4.79981 6.3459 3.51809 8.17316 2.76121C10.0004 2.00433 12.0111 1.8063 13.9509 2.19215C15.8907 2.578 17.6725 3.53041 19.0711 4.92894C20.4696 6.32746 21.422 8.10929 21.8079 10.0491C22.1937 11.9889 21.9957 13.9996 21.2388 15.8268C20.4819 17.6541 19.2002 19.2159 17.5557 20.3147C15.9112 21.4135 13.9778 22 12 22C9.34873 21.9971 6.80688 20.9426 4.93215 19.0679C3.05741 17.1931 2.00291 14.6513 2 12Z"
                        fill="#ADA8F3"
                      />
                      <path
                        d="M9.08327 11.9998C9.08289 11.781 9.12576 11.5642 9.20942 11.362C9.29308 11.1598 9.41588 10.9761 9.57077 10.8215L13.9824 6.41064L15.1608 7.58898L10.7499 11.9998L15.1608 16.4106L13.9824 17.589L9.5716 13.1781C9.41657 13.0236 9.29361 12.8399 9.2098 12.6377C9.126 12.4355 9.08299 12.2187 9.08327 11.9998Z"
                        fill="#374957"
                      />
                    </svg>

                    <span className="absolute inset-0 opacity-0 pointer-events-none">vorige</span>
                  </button>
                  <button className="posts-related-button-next relative">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M-5.24537e-07 12C-4.20793e-07 14.3734 0.703787 16.6934 2.02236 18.6668C3.34094 20.6402 5.21509 22.1783 7.4078 23.0865C9.60051 23.9948 12.0133 24.2324 14.3411 23.7694C16.6689 23.3064 18.8071 22.1635 20.4853 20.4853C22.1635 18.807 23.3064 16.6688 23.7694 14.3411C24.2324 12.0133 23.9948 9.6005 23.0866 7.40779C22.1783 5.21508 20.6402 3.34094 18.6668 2.02236C16.6935 0.703782 14.3734 -6.35033e-06 12 -6.24658e-06C8.81846 0.00343475 5.76821 1.26882 3.51852 3.51851C1.26883 5.7682 0.00344042 8.81845 -5.24537e-07 12H-5.24537e-07ZM22 12C22 13.9778 21.4135 15.9112 20.3147 17.5557C19.2159 19.2002 17.6541 20.4819 15.8268 21.2388C13.9996 21.9957 11.9889 22.1937 10.0491 21.8078C8.10929 21.422 6.32746 20.4696 4.92893 19.0711C3.53041 17.6725 2.578 15.8907 2.19215 13.9509C1.80629 12.0111 2.00433 10.0004 2.7612 8.17316C3.51808 6.3459 4.79981 4.78411 6.4443 3.6853C8.08879 2.58648 10.0222 1.99999 12 1.99999C14.6513 2.00291 17.1931 3.05741 19.0679 4.93214C20.9426 6.80687 21.9971 9.34872 22 12Z"
                        fill="#ADA8F3"
                      />
                      <path
                        d="M14.9167 12.0002C14.9171 12.219 14.8742 12.4358 14.7906 12.638C14.7069 12.8402 14.5841 13.0239 14.4292 13.1785L10.0176 17.5894L8.83923 16.411L13.2501 12.0002L8.83923 7.58936L10.0176 6.41102L14.4284 10.8219C14.5834 10.9764 14.7064 11.1601 14.7902 11.3623C14.874 11.5645 14.917 11.7813 14.9167 12.0002Z"
                        fill="#374957"
                      />
                    </svg>
                    <span className="absolute inset-0 opacity-0 pointer-events-none">volgende</span>
                  </button>
                </div>
              </div>
            </div>
            <Slider
              modules={[Autoplay, Navigation]}
              autoplay={{
                delay: 5000
              }}
              navigation={{
                prevEl: '.posts-related-button-prev',
                nextEl: '.posts-related-button-next'
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1.5,
                  spaceBetween: 15
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30
                }
              }}
              className={clsx('rounded-xl overflow-hidden !py-4 max-sm:!-mr-4 sm:max-md:!-mr-20 sm:max-md:!pr-8 max-sm:!pr-4')}
            >
              {_posts?.map((item) => (
                <Slide key={item.databaseId} className="!h-auto">
                  <Post data={item as PostProps} />
                </Slide>
              ))}
            </Slider>
          </div>
          <div className="col-span-12 lg:col-span-4 2xl:col-span-3 pb-3">
            <div className="relative rounded-2xl overflow-hidden h-full">
              <div className="absolute bg-gradient w-full h-full inset-0 -scale-x-[1]"></div>
              <div className="relative p-8 rounded-2xl overflow-hidden h-full w-full">
                <p className="text-xl text-white font-semibold mb-6">{fields.form.description}</p>
                <Form
                  className="form-newsletter"
                  data={fields.form}
                  renderLoading={() => <Loading />}
                  generate
                  renderStatus={({ content }) => <Content className="content">{content}</Content>}
                  scrollToConfirm={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
