import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import { motion } from 'framer-motion'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section className="bg-site-light" data-component="BannerDefault">
      <div className="container section sm:grid flex flex-col grid-cols-2 items-start pt-4 lg:pt-12 relative">
        <div className="rounded-2xl sm:relative absolute right-6 top-4">
          <motion.div
            className="z-20 relative"
            initial={{ rotate: 20, left: -50 }}
            animate={{
              rotate: 0,
              left: 0,
              transition: {
                duration: 0.5
              }
            }}
            whileHover={{}}
          >
            <LossePlaatjie
              loading="eager"
              className="xl:w-full xl:h-full sm:w-[105%] ml-auto sm:h-[105%] xs:w-[125px] xs:h-[125px] w-[75px] h-[75px] max-w-[600px] object-contain xl:-ml-24 lg:-ml-28 sm:-ml-16 xl:mt-2 mt-6"
              src={fields.image}
              maxwidth={900}
            />
          </motion.div>

          <div className="absolute sm:block hidden -left-[78%] -top-[73%] w-[250%] h-[250%] bg-gradient-radial from-site-base-400 via-transparent z-10" />
        </div>

        <div className="xl:px-32 lg:px-28 sm:px-20 px-6 -mx-4 2xl:-mr-20 xl:-mr-0 lg:-mr-20 sm:-mr-10 xl:py-16 py-12 bg-gradient max-lg:py-14 sm:rounded-l-[50px] sm:rounded-r-[50px] xl:-ml-52 lg:-ml-40 sm:-ml-28 relative z-10">
          <Content className="content content-gradient max-sm:children-p:font-medium lg:children-h1:text-7xl sm:children-h1:text-6xl xs:children-h1:text-4xl children-h1:text-3xl lg:children-strong:text-6xl sm:children-strong:text-5xl children-strong:text-3xl children-h1:mb-10 children-strong:font-outline-white">
            {fields?.content}
          </Content>

          {fields.links && (
            <div className="mt-12 sm:mb-4">
              {fields.links.map((edge) => {
                return (
                  <Button key={edge.link.title} to={edge.link.url} className="btn-white">
                    {edge.link.title}
                  </Button>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
