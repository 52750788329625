export default function Envelope({ className }: { className?: string }) {
  return (
    <svg className={className ?? undefined} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3197 4.82403V4.56146C17.3197 3.57284 16.5154 2.76855 15.5268 2.76855H2.4726C1.48397 2.76855 0.679688 3.57284 0.679688 4.56146V4.82403V4.82595V13.4384C0.679688 14.4269 1.48397 15.2312 2.4726 15.2312H15.5269C16.5154 15.2312 17.3197 14.4269 17.3197 13.4384V4.82595C17.3197 4.82533 17.3197 4.82468 17.3197 4.82403ZM2.4726 3.74356H15.5268C15.9778 3.74356 16.3447 4.11046 16.3447 4.56146V4.56169L9.81551 8.91445C9.38092 9.20419 8.61852 9.20416 8.184 8.91448L1.6547 4.56166V4.56143C1.6547 4.11046 2.02159 3.74356 2.4726 3.74356ZM15.5269 14.2562H2.4726C2.02159 14.2562 1.6547 13.8893 1.6547 13.4384V5.73346L7.64316 9.72572C8.02354 9.97932 8.51153 10.1061 8.99972 10.1061C9.48778 10.1061 9.97599 9.97929 10.3563 9.72572L16.3447 5.73346V13.4384C16.3447 13.8893 15.9778 14.2562 15.5269 14.2562Z"
        fill="#44277C"
      />
    </svg>
  )
}
