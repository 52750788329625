import { LosseLink, LossePlaatjie } from "@ubo/losse-sjedel";
import Content from "~/components/elements/Content";
import Rainbow from "~/components/elements/Rainbow";
import type { Page_Flexcontent_Flex_Content } from "~/graphql/types";

export default function ContentServices({ fields }: { fields: Page_Flexcontent_Flex_Content }) {

  const first = fields?.items[0];
  const rest = fields?.items.slice(1);

  return (
    <section data-component="ContentServices" className="section">
      <div className="absolute top-0 left-0 h-3/4 w-full bg-gradient-to-t from-white to-site-light -z-20" />
      <div className="relative w-full h-full top-28">
        <div
          className="absolute top-[50px] -left-[50vw] w-[200vw] h-[1000px] -rotate-[15deg] -z-20"
          style={{
            background:
              'linear-gradient(0deg, rgba(68, 39, 124, 0.00) 0%, rgba(68, 39, 124, 0.45) 38.54%, rgba(68, 39, 124, 0.45) 69.79%, rgba(68, 39, 124, 0.00) 100%)'
          }}
        ></div>
        <Rainbow className="absolute top-[200px] -rotate-[37.5deg] -z-10" />
      </div>
      <div className="relative container">
        <div className="grid md:grid-cols-2 gap-8 mb-10 lg:mb-20">
          <div className="sm:col-span-1">
            <Content className="content xl:children-strong:text-7xl lg:children-strong:text-6xl md:children-strong:text-5xl children-strong:text-3xl xl:children-h2:text-7xl md:children-h2:text-6xl children-h2:text-5xl children-strong:font-outline-site-base">{fields?.title}</Content>
          </div>
          <div className="sm:col-span-1">
            <Content className="content">{fields?.description}</Content>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="col-span-1 bg-glass-gradient-alt !border-white p-6 md:p-8 rounded-[20px]">
            <LosseLink to={first?.link?.url} className="bg-white block hover:bg-site-light-100 rounded-[20px] p-8 h-full">
              <div className="bg-[#F1F1F1] w-[64px] h-[64px] flex items-center justify-center rounded-[10px] mb-4 lg:mb-5">
                <LossePlaatjie src={first?.icon} maxwidth={100} className="max-lg:w-[35px] max-lg:h-[35px]" />
              </div>
              <h3 className="font-semibold text-site-base group-hover:text-white text-lg lg:text-xl xl:text-3xl lg:mb-4">
                {first.link.title}
              </h3>
            </LosseLink>
          </div>
          <div className="sm:col-span-1 lg:col-span-3 bg-glass-gradient-alt p-8 rounded-[20px]">
            <div className="grid sm:grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8">
              <div className="sm:col-span-1">
                <LosseLink to={rest[0]?.link?.url} className="bg-white flex items-center lg:block hover:bg-site-light-100 rounded-[20px] p-6 md:p-8 h-full">
                  <div className="bg-[#F1F1F1] w-[64px] h-[64px] flex items-center justify-center rounded-[10px] mb-4 lg:mb-5">
                    <LossePlaatjie src={rest[0]?.icon} maxwidth={100} className="max-lg:w-[35px] max-lg:h-[35px]" />
                  </div>
                  <h3 className="font-semibold text-site-base group-hover:text-white text-lg lg:text-xl xl:text-3xl lg:mb-4 ml-4 lg:ml-0">
                    {rest[0].link.title}
                  </h3>
                </LosseLink>
              </div>
              <div className="sm:col-span-1 lg:col-span-2">
                <LosseLink to={rest[1]?.link?.url} className="bg-white hover:bg-site-light-100  rounded-[20px] p-6 md:p-8 mb-4 lg:mb-8 flex items-center">
                  <div className="bg-[#F1F1F1] w-[64px] h-[64px] flex items-center justify-center rounded-[10px] mb-4 lg:mb-5">
                    <LossePlaatjie src={rest[1]?.icon} maxwidth={100} className="max-lg:w-[35px] max-lg:h-[35px]" />
                  </div>
                  <h3 className="font-semibold text-site-base group-hover:text-white text-lg lg:text-xl xl:text-3xl lg:mb-4 ml-4">
                    {rest[1].link.title}
                  </h3>
                </LosseLink>
                <LosseLink to={rest[2]?.link?.url} className="bg-white hover:bg-site-light-100 rounded-[20px] p-6 md:p-8 flex items-center">
                  <div className="bg-[#F1F1F1] w-[64px] h-[64px] flex items-center justify-center rounded-[10px] mb-4 lg:mb-5">
                    <LossePlaatjie src={rest[2]?.icon} maxwidth={100} className="max-lg:w-[35px] max-lg:h-[35px]" />
                  </div>
                  <h3 className="font-semibold text-site-base group-hover:text-white text-lg lg:text-xl xl:text-3xl lg:mb-4 ml-4">
                    {rest[2].link.title}
                  </h3>
                </LosseLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
