import type { ComponentPropsWithoutRef } from 'react'
import { LosseContent, losseContentToReact, useLosseLanguage } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import clsx from 'clsx'

export default function Content({ ...rest }: ComponentPropsWithoutRef<'div'>) {
  const [, locale] = useLosseLanguage()

  return (
    <LosseContent
      {...rest}
      className={clsx(rest.className, 'content max-w-[75ch]')}
      replace={(domNode: any) => {
        const { name, attribs, children } = domNode

        // if iframe and iframe src contains youtube.com then add data-cookieblock-src and data-cookieconsent
        if (name === 'iframe') {
          // check if attribs.src contains youtube.com and remove attribs.src and show warning message
          if (attribs.src.includes('youtube.com')) {
            attribs['data-cookieblock-src'] = attribs.src
            attribs['data-cookieconsent'] = 'marketing'
            delete attribs.src
            return (
              <div className="aspect-video overflow-hidden relative">
                <iframe {...attribs} />
                <div className="cookieconsent-optout-marketing absolute top-0 aspect-video w-full flex items-center justify-center">
                  <button
                    type="button"
                    onClick={() => {
                      // @ts-ignore
                      if (typeof Cookiebot !== 'undefined') Cookiebot.show()
                    }}
                    className="hover:underline btn"
                  >
                    {locale === 'nl_NL'
                      ? 'Accepteer de marketingcookies om deze video te bekijken'
                      : 'Please accept marketing-cookies to watch this video.'}
                  </button>
                </div>
              </div>
            )
          }
        }

        if (name === 'a') {
          const attributes = { ...attribs }

          attributes.className = attributes.class
          delete attributes.class

          if (attribs.class === 'button') {
            return (
              <Button to={attributes.href} {...attributes}>
                {losseContentToReact(children)}
              </Button>
            )
          }
        }

        return null
      }}
    >
      {rest.children}
    </LosseContent>
  )
}
