import { useLosseBlogBink, useLosseLanguage, useSearchParams } from '@ubo/losse-sjedel'
import type { ChangeEvent } from 'react'
import { useRef } from 'react'
import type { Category, Maybe } from '~/graphql/types'
import { READABLE_PARAMS } from './PostsBlog'

const SORT: { [key: string]: string[] } = {
  nl_NL: ['Migratie', 'Beheer', 'Beveiliging', 'Moderne werkplek'],
  en_US: ['Migration', 'Management', 'Security', 'Modern workplace']
}

export default function Filters({ filters }: { filters: Maybe<Category[]> | undefined }) {
  const selectRef = useRef<HTMLSelectElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [, lang] = useLosseLanguage()
  const bb = useLosseBlogBink()

  function filterChange(e: ChangeEvent<HTMLSelectElement>) {
    searchParams.set(READABLE_PARAMS.filters, e.target.value)
    setSearchParams(searchParams, {
      state: {
        scroll: false
      }
    })
  }

  function handleClear() {
    selectRef.current.value = ''

    bb.clear()
  }

  return (
    <div className="flex flex-col md:flex-row items-center">
      <label htmlFor="select-category" className="font-semibold text-white max-md:mt-6">
        {lang === 'nl_NL' ? 'Of filter op onderwerp' : 'Or filter on subject'}
      </label>
      <div className="relative">
        <select
          ref={selectRef}
          onChange={(e) => filterChange(e)}
          name="select-category"
          id="select-category"
          className="appearance-none bg-white rounded-full pl-5 pr-12 py-2 xl:py-3 md:ml-4 lg:min-w-[276px] children-option:text-black text-black"
          defaultValue={searchParams.get(READABLE_PARAMS.filters) || ''}
        >
          <option disabled value="">
            {lang === 'nl_NL' ? 'Selecteer onderwerp' : 'Select subject'}
          </option>
          {filters
            ?.sort((a, b) => {
              if (SORT[lang].indexOf(a.name) > SORT[lang].indexOf(b.name)) {
                return 1
              } else {
                return -1
              }
            })
            ?.map((edge) => {
              return (
                <option key={edge.databaseId} value={edge.databaseId}>
                  {edge.name}
                </option>
              )
            })}
        </select>
        <svg
          className="absolute top-[2px] bottom-0 right-3 my-auto pointer-events-none"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z"
            fill="#ADA8F3"
          />
          <path
            d="M12.0003 14.9167C11.7815 14.9171 11.5647 14.8742 11.3625 14.7906C11.1603 14.7069 10.9766 14.5841 10.822 14.4292L6.41113 10.0176L7.58947 8.83923L12.0003 13.2501L16.4111 8.83923L17.5895 10.0176L13.1786 14.4284C13.0241 14.5834 12.8404 14.7064 12.6382 14.7902C12.436 14.874 12.2192 14.917 12.0003 14.9167Z"
            fill="#374957"
          />
        </svg>
      </div>

      <button className="ml-4 max-md:mt-4 text-white" onClick={() => handleClear()}>
        {lang === 'nl_NL' ? 'wis filters' : 'clear filters'}
      </button>
    </div>
  )
}
