import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Page_Flexcontent_Flex_Hero } from '~/graphql/types'

export default function HeroDefault({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  return (
    <section
      data-component="HeroDefault"
      className={clsx(fields.overlapPreviousSection && '-mt-[15%]', fields.bgcolor === 'lightpurple' && 'bg-site-light', 'relative')}
    >
      {fields.showBackgroundCube && (
        <img
          src="/static/hero-cubes.png"
          alt=""
          className="absolute lg:-right-0 -top-1/4 pointer-events-none w-[750px] h-[750px] max-lg:hidden"
        />
      )}
      <div className="relative">
        <div
          className="absolute bottom-2 left-0 h-[124px] w-full"
          style={{
            background:
              'linear-gradient(0deg, rgba(68, 39, 124, 0.00) 0%, rgba(68, 39, 124, 0.45) 38.54%, rgba(68, 39, 124, 0.45) 69.79%, rgba(68, 39, 124, 0.00) 100%)'
          }}
        ></div>
        <div className="relative z-20 max-w-screen-2xl mx-auto">
          <LossePlaatjie src={fields.image} maxWidth={1920} className="md:w-[75%]" />
        </div>
      </div>
    </section>
  )
}
