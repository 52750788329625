import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import A from '~/components/elements/svg/A'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentIframe({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentIframe" className="section bg-site-light" id={fields?.sectionId ?? undefined}>
      <div className="container">
        <div className="lg:max-w-screen-lg mx-auto xl:px-20 lg:px-14 xl:pt-20 lg:pt-14 px-10 pt-10 bg-white shadow rounded-2xl overflow-hidden">
          <div className="md:grid grid-cols-2 lg:mb-20 mb-10 md:items-center md:gap-0 flex-col flex gap-10">
            <Content className="content lg:children-strong:text-6xl sm:children-strong:text-5xl children-strong:text-4xl lg:children-h2:text-5xl sm:children-h2:text-4xl  children-strong:font-outline-site-base children-strong:font-outline-site-base">
              {fields?.description}
            </Content>

            <div className="bg-site-base-200 text-white rounded-full p-2 inline-flex ml-auto items-center">
              <Content className="content children-p:text-white children-p:font-bold mx-10 children-p:leading-snug">
                {fields.iframe.meetText}
              </Content>
              <div className="rounded-full w-[100px] h-[100px] bg-site-base flex items-center justify-center">
                {fields.iframe.meetImage ? (
                  <LossePlaatjie className="object-cover" maxWidth={150} src={fields.iframe.meetImage} />
                ) : (
                  <A className="w-[70%] h-[70%] -mt-[8px]" />
                )}
              </div>
            </div>
          </div>

          <div>
            <Content className="max-w-none lg:children-iframe:w-[1024px] md:children-iframe:w-[768px] children-iframe:w-full children-iframe:h-[1725px] xl:children-iframe:h-[1540px] xl:-mx-20 lg:-mx-14 -mx-10">
              {fields.iframe.code}
            </Content>
          </div>
        </div>
      </div>
    </section>
  )
}
