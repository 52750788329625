export default function X({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" fill="none" viewBox="0 0 22 20" className={className ?? undefined}>
      <path
        fill="#ADA8F3"
        d="M17.106-.107h3.364l-7.346 8.562 8.641 11.652h-6.764L9.698 13.04l-6.06 7.066H.272l7.855-9.16L-.158-.107h6.936l4.788 6.458 5.54-6.458zm-1.181 18.163h1.863L5.763 1.836h-2l12.162 16.22z"
      ></path>
    </svg>
  )
}
