export default function ArrowLeft() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path fill="#ADA8F3" d="M24 12a12 12 0 10-12 12 12.013 12.013 0 0012-12zM2 12a10 10 0 1110 10A10.011 10.011 0 012 12z"></path>
      <path
        fill="#374957"
        d="M9.084 12a1.66 1.66 0 01.487-1.178l4.412-4.411 1.178 1.178-4.41 4.41 4.41 4.412-1.178 1.178-4.411-4.41a1.661 1.661 0 01-.488-1.18z"
      ></path>
    </svg>
  )
}
