export default function Phone({ className }: { className?: string }) {
  return (
    <svg className={className ?? undefined} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.198 13.121L13.8509 12.5415C13.2268 11.5125 12.3655 10.3315 11.2357 10.3315C11.0264 10.3315 10.8191 10.3736 10.6139 10.4588L10.0075 10.7189C9.95209 10.7418 9.89828 10.7679 9.84132 10.7956C9.68612 10.8711 9.51013 10.9566 9.3291 10.9566C8.88256 10.9566 8.36516 10.3755 7.87246 9.32038C7.38891 8.28476 7.41976 7.74185 7.53076 7.46867C7.65324 7.16727 7.93805 7.03841 8.24403 6.92261C8.28659 6.90647 8.32501 6.89184 8.36239 6.87645L8.97644 6.6179C10.5762 5.94891 9.98106 3.61103 9.78593 2.84454L9.62045 2.18553C9.47898 1.64239 9.10388 0.199707 7.8597 0.199707C7.62937 0.199707 7.38366 0.253368 7.12962 0.359263C6.96293 0.425457 4.6691 1.36178 3.83807 3.00673C2.84486 4.9647 3.02851 7.59033 4.38339 10.809C5.72813 14.0317 7.47192 16.0029 9.56634 16.6677C9.9256 16.7818 10.3316 16.8396 10.7732 16.8396H10.7734C12.2188 16.8396 13.6456 16.2247 13.7614 16.1736C14.2597 15.9625 14.5819 15.6417 14.7187 15.22C14.9508 14.5048 14.5615 13.7213 14.198 13.121ZM13.6479 14.8725C13.616 14.9705 13.5054 15.06 13.3193 15.1383C13.3162 15.1397 13.3125 15.1413 13.3093 15.1427C13.2964 15.1484 12.0049 15.714 10.7731 15.714C10.4473 15.714 10.1559 15.6739 9.90699 15.5948C8.14249 15.0347 6.63369 13.2787 5.42155 10.3739C4.20042 7.47272 4.00529 5.16539 4.84239 3.51519C5.4924 2.22861 7.52738 1.41244 7.54734 1.40463C7.5514 1.40298 7.55537 1.40141 7.55935 1.39976C7.67523 1.35112 7.7791 1.32546 7.8597 1.32546C8.10774 1.32546 8.33349 1.71016 8.52967 2.46419L8.69441 3.12043C9.04984 4.51636 8.99573 5.38949 8.5407 5.57981L7.92957 5.83723C7.90526 5.84729 7.87681 5.85787 7.84544 5.86981C7.50787 5.99762 6.8054 6.26344 6.48771 7.04486C6.19945 7.75393 6.31878 8.65408 6.85216 9.79679C7.57053 11.3348 8.3807 12.0824 9.32895 12.0824C9.76905 12.0824 10.1223 11.9107 10.3333 11.8082C10.3722 11.7893 10.407 11.772 10.4443 11.7565L11.0517 11.4961C11.1143 11.47 11.1745 11.4573 11.2356 11.4573C11.5279 11.4573 12.0521 11.7467 12.8868 13.1227L13.2336 13.7018C13.661 14.4075 13.6948 14.728 13.6479 14.8725Z"
        fill="#44277C"
      />
    </svg>
  )
}
