import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentColumns({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentColumns" className="section bg-site-light relative">
      {fields.bgeffect && (
        <div className="absolute -left-[100%] sm:-top-[50%] -top-[30%] w-[300%] sm:h-[169%] h-[150%] bg-gradient-radial from-site-base-400 via-transparent pointer-events-none" />
      )}

      <div className="container relative">
        {fields.description && (
          <Content className="mb-12 children-strong:font-outline-site-base children-h2:text-2xl xs:children-h2:text-3xl md:children-h2:text-5xl lg:children-h2:text-7xl max-w-[unset]">
            {fields.description}
          </Content>
        )}
        <div className="sm:grid flex flex-col grid-cols-2 lg:gap-14 gap-8">
          {fields.columns.map((column, index) => {
            const isThird = (index + 1) % 3 === 0
            return (
              <div
                key={index}
                className={clsx(
                  'rounded-2xl lg:py-14 py-10 lg:px-20 px-10 text-site-base',
                  column.descriptionBottom && 'flex flex-col justify-between',
                  isThird
                    ? 'col-span-2 sm:grid flex flex-col grid-cols-2 sm:children:mx-auto bg-gradient text-white sm:items-center'
                    : 'shadow bg-white'
                )}
              >
                <Content
                  className={clsx(
                    'content [&_a]:no-underline lg:children-h2:min-h-[120px] xl:[&_h2>strong]:text-6xl lg:[&_h2>strong]:text-5xl md:[&_h2>strong]:text-4xl [&_h2>strong]:text-2xl children-a:btn children-a:btn-light children-a:hover:text-white xl:children-h2:text-4xl md:children-h2:text-3xl children-h2:text-xl',
                    isThird ? ' [&_h2>strong]:font-outline-white children:!text-white' : ' [&_h2>strong]:font-outline-site-base'
                  )}
                >
                  {column.description}
                </Content>
                <Content
                  className={clsx(
                    'content mt-6 [&_a]:no-underline lg:children-h2:min-h-[120px] xl:[&_h2>strong]:text-6xl lg:[&_h2>strong]:text-5xl md:[&_h2>strong]:text-4xl [&_h2>strong]:text-2xl children-a:btn children-a:btn-light children-a:hover:text-white xl:children-h2:text-4xl md:children-h2:text-3xl children-h2:text-xl',
                    isThird ? ' [&_h2>strong]:font-outline-white children:!text-white' : ' [&_h2>strong]:font-outline-site-base'
                  )}
                >
                  {column.descriptionBottom}
                </Content>

                {column.links && column.links.length > 0 && (
                  <ul className={clsx('flex flex-col md:gap-8 gap-4', isThird ? 'sm:mt-0 mt-8' : 'sm:mt-10 mt-8')}>
                    {column.links.map((link) => (
                      <li className="relative" key={link.link.title}>
                        <LosseLink
                          className="hover:translate-x-4 transition-transform flex items-center gap-x-6"
                          to={link.link.url === '#' ? undefined : link.link.url}
                          target={link.link.target}
                        >
                          <svg
                            className="block flex-none md:w-[22px] md:h-[24px] w-[18px] h-[20px]"
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="24"
                            fill="none"
                            viewBox="0 0 22 24"
                          >
                            <path
                              fill="currentColor"
                              d="M5.361 20.937L.06 24c.934-3.563 1.609-7.607 1.609-11.95C1.668 7.707.972 3.523 0 0l5.38 3.122v-.02l1.152.7 15.466 8.226-15.466 8.287-1.152.64-.02-.02.001.002z"
                            ></path>
                          </svg>

                          <Content
                            className={clsx(
                              'content lg:children-p:text-xl md:children-p:text-lg',
                              isThird ? 'children:!text-white children:!font-bold' : ''
                            )}
                          >{`<p>${link.link.title}</p>`}</Content>
                        </LosseLink>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
