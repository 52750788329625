export default function ChevronRoundedRight({ className }: { className?: string }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className ?? undefined}>
      <path
        d="M-5.24537e-07 12C-4.20793e-07 14.3734 0.703787 16.6934 2.02236 18.6668C3.34094 20.6402 5.21509 22.1783 7.4078 23.0865C9.60051 23.9948 12.0133 24.2324 14.3411 23.7694C16.6689 23.3064 18.8071 22.1635 20.4853 20.4853C22.1635 18.807 23.3064 16.6688 23.7694 14.3411C24.2324 12.0133 23.9948 9.6005 23.0866 7.40779C22.1783 5.21508 20.6402 3.34094 18.6668 2.02236C16.6935 0.703782 14.3734 -6.35033e-06 12 -6.24658e-06C8.81846 0.00343475 5.76821 1.26882 3.51852 3.51851C1.26883 5.7682 0.00344042 8.81845 -5.24537e-07 12H-5.24537e-07ZM22 12C22 13.9778 21.4135 15.9112 20.3147 17.5557C19.2159 19.2002 17.6541 20.4819 15.8268 21.2388C13.9996 21.9957 11.9889 22.1937 10.0491 21.8078C8.10929 21.422 6.32746 20.4696 4.92893 19.0711C3.53041 17.6725 2.578 15.8907 2.19215 13.9509C1.80629 12.0111 2.00433 10.0004 2.7612 8.17316C3.51808 6.3459 4.79981 4.78411 6.4443 3.6853C8.08879 2.58648 10.0222 1.99999 12 1.99999C14.6513 2.00291 17.1931 3.05741 19.0679 4.93214C20.9426 6.80687 21.9971 9.34872 22 12Z"
        fill="#ADA8F3"
      />
      <path
        d="M14.9164 12.0001C14.9167 12.2189 14.8739 12.4357 14.7902 12.6379C14.7066 12.8401 14.5838 13.0238 14.4289 13.1784L10.0172 17.5892L8.83887 16.4109L13.2497 12.0001L8.83887 7.58923L10.0172 6.4109L14.428 10.8217C14.5831 10.9763 14.706 11.1599 14.7898 11.3622C14.8736 11.5644 14.9166 11.7812 14.9164 12.0001Z"
        fill="#374957"
      />
    </svg>
  )
}
