import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import CallToActionDefault from './CallToActionDefault'

export default function CallToActionShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: CallToActionDefault
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
