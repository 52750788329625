import { useEffect, useState } from 'react'
import { LosseLink, useLoaderData, useLocation, useLosseLanguage } from '@ubo/losse-sjedel'
import { AnimatePresence } from 'framer-motion'
import HeaderSubMenu from './HeaderSubmenu'
import type { HeaderLoaderData } from './Header'
import Button from '~/components/elements/Button'
import type { Component_Header_MenuHeader_Submenu } from '~/graphql/types'
import clsx from 'clsx'

export default function HeaderMenu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [translations, currentLocale] = useLosseLanguage()
  const location = useLocation()

  function handleSubmenu(open: boolean, index: number) {
    if (open) {
      setCurrentIndex(-1)
    } else {
      setCurrentIndex(index)
    }
  }

  useEffect(() => {
    setCurrentIndex(-1)
  }, [location.pathname])

  let translationUrl = translations?.[0]?.href || '/'

  if (translationUrl === '/en/alta-ict-always-the-best-of-microsoft-365/') {
    translationUrl = '/en/'
  } else if (translationUrl === '/alta-ict-altijd-het-beste-van-microsoft-365/') {
    translationUrl = '/'
  }

  return (
    <>
      <ul className="w-full items-center lg:justify-end flex flex-col lg:flex-row lg:mr-4 xl:mr-12">
        {header?.menuHeader?.map((edge, index) => {
          const submenuOpen = currentIndex === index

          const cleanUrl = edge.link.url.replace(/[\\/]/g, '')
          const parentPageUrl = location.pathname.split('/')[1]

          const partiallyCurrent = cleanUrl === parentPageUrl

          return (
            <li
              className="lg:mx-3 xl:mx-5 text-3xl lg:text-base max-lg:py-1"
              key={index}
              onMouseEnter={() => setCurrentIndex(index)}
              onMouseLeave={() => setCurrentIndex(-1)}
              onClick={(e) => {
                e.stopPropagation()

                setCurrentIndex(index)
              }}
            >
              <div className="group flex flex-wrap justify-center items-center">
                <LosseLink className="group relative font-semibold text-site-base px-2" to={edge?.link?.url || '/'}>
                  <div
                    className={clsx(
                      partiallyCurrent || submenuOpen
                        ? 'translate-y-[1px] opacity-100'
                        : 'translate-y-1 group-hover:translate-y-[1px] opacity-0 group-hover:opacity-100',
                      'absolute bottom-0 left-0 w-full bg-site-base-50 h-3 rounded-full transition-transform'
                    )}
                  ></div>
                  <span className="relative">{edge?.link?.title}</span>
                </LosseLink>
                {edge?.submenu && (
                  <div
                    onClick={() => handleSubmenu(submenuOpen, index)}
                    className={clsx(currentIndex === index ? 'rotate-90' : 'rotate-0', 'ml-1 mt-1 transition-transform')}
                    aria-label={`Submenu ${edge?.link?.title}`}
                  >
                    <svg
                      className="max-lg:w-7 max-lg:h-5"
                      width="6"
                      height="12"
                      viewBox="0 0 6 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.10833 11.0302L0.0500793 9.96973L4.01682 5.99998L0.0500793 2.03023L1.11133 0.969727L5.07507 4.93948C5.35628 5.22077 5.51425 5.60223 5.51425 5.99998C5.51425 6.39772 5.35628 6.77918 5.07507 7.06048L1.10833 11.0302Z"
                        fill="#44277C"
                      />
                    </svg>
                  </div>
                )}
              </div>
              {edge?.submenu && submenuOpen && (
                <div className="w-screen flex justify-center absolute left-0">
                  <div className="container">
                    <AnimatePresence>
                      <HeaderSubMenu fields={edge.submenu as Component_Header_MenuHeader_Submenu[]} />
                    </AnimatePresence>
                  </div>
                </div>
              )}
            </li>
          )
        })}
      </ul>
      <ul className="flex flex-col lg:flex-row items-center">
        <li className="lg:order-last max-lg:pb-1 max-lg:pt-2">
          <Button to={header.ctaHeader.url} className="btn-default">
            {header.ctaHeader.title}
          </Button>
        </li>
        <li className="group lg:mr-9 lg:order-first max-lg:py-2">
          <LosseLink to={translationUrl} className="flex items-center text-sm xl:text-base">
            <span
              className={clsx(
                currentLocale === 'nl_NL' ? 'font-semibold' : 'group-hover:underline opacity-50 group-hover:opacity-100',
                'mr-2'
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="12"
                fill="none"
                viewBox="0 0 36 24"
                className="h-[16px] w-[24px] overflow-hidden rounded"
              >
                <g clipPath="url(#a)">
                  <path fill="#F0F0F0" d="M36 0H0v23.996h36V0Z" />
                  <path fill="#0052B4" d="M36 15.997H0v7.999h36v-7.999Z" />
                  <path fill="#FF4B55" d="M36 0H0V8h36V0Z" />
                </g>
              </svg>
            </span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" value="" className="sr-only peer" />
              <div
                className={clsx(
                  'w-11 h-6 peer-focus:outline-none peer-focus:ring-2 rounded-full peer bg-white peer-checked:after:translate-x-full peer-checked:after:border-site-base-200 after:absolute after:top-[2px] after:bg-site-base-200 after:border-site-base-200 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600',
                  currentLocale === 'nl_NL' ? 'after:left-[2px]' : 'after:right-[2px]'
                )}
              ></div>
            </label>
            <span
              className={clsx(
                currentLocale === 'nl_NL' ? 'group-hover:underline opacity-50 group-hover:opacity-100' : 'font-semibold',
                'ml-2'
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="12"
                fill="none"
                viewBox="0 0 36 24"
                className="h-[16px] w-[24px] overflow-hidden rounded"
              >
                <g clipPath="url(#a)">
                  <path fill="#0052B4" d="M36 0H0v23.996h36V0Z" />
                  <path
                    fill="#F0F0F0"
                    fillRule="evenodd"
                    d="M36 0v2.5l-9.052 6.033-2.08 1.386-3.119 2.079 3.12 2.08L36 21.496v2.499h-3.75l-10.784-7.188-1.386-.925L18 14.497l-2.08 1.386-1.386.925-10.785 7.188H0v-2.5l9.052-6.033 2.08-1.386 3.119-2.079-3.12-2.08-2.079-1.385L0 2.499V0h3.75l10.784 7.188 1.386.925L18 9.499l2.08-1.386 1.386-.925L32.251 0H36Z"
                    clipRule="evenodd"
                  />
                  <path
                    fill="#FF4B55"
                    fillRule="evenodd"
                    d="m23.618 9.919-3.12 2.079 3.12 2.08 2.08 1.385L36 22.331v1.665h-2.498l-12.036-8.023-1.386-.924L18 13.663l-2.08 1.386-1.386.924-12.036 8.023H0v-1.665l10.303-6.868 2.08-1.386 3.119-2.079-3.12-2.08-2.079-1.385L0 1.665V0h2.498l12.036 8.023 1.386.924L18 10.333l2.08-1.386 1.386-.924L33.502 0H36v1.665L25.697 8.533l-2.08 1.386Z"
                    clipRule="evenodd"
                  />
                  <path
                    fill="#F0F0F0"
                    fillRule="evenodd"
                    d="M21.466 15.464v8.532h-6.932v-8.532H0V8.533h14.534V0h6.932v8.533H36v6.93H21.466Z"
                    clipRule="evenodd"
                  />
                  <path
                    fill="#FF4B55"
                    fillRule="evenodd"
                    d="M20.08 14.077v9.919h-4.16v-9.919H0V9.92h15.92V0h4.16V9.92H36v4.158H20.08Z"
                    clipRule="evenodd"
                  />
                </g>
              </svg>
            </span>
          </LosseLink>
        </li>
      </ul>
    </>
  )
}
