import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Employee, Page_Flexcontent_Flex_Posts } from '~/graphql/types'

export default function Team({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="Team" className="section bg-site-light relative">
      <div className="absolute -left-[100%] sm:-top-[30%] -top-[30%] w-[300%] sm:h-[169%] h-[150%] bg-gradient-radial from-site-base-400/40 via-transparent" />
      <img src="/static/team-bg.png" className="absolute bottom-0 right-0 w-[1200px] h-auto object-contain" alt="Zwevende kubus" />
      <div className="container sm:grid flex flex-col grid-cols-2 xl:gap-32 lg:gap-14 gap-10 relative ">
        <div className="relative">
          <Content className="content content xl:children-strong:text-7xl lg:children-strong:text-6xl md:children-strong:text-5xl children-strong:text-3xl   xl:children-h2:text-7xl md:children-h2:text-6xl children-h2:text-5xl children-strong:font-outline-site-base ">
            {fields.description}
          </Content>
        </div>

        {fields.teammembers.edges.map((post, index) => {
          const member = post.node as Employee
          const isRight = index % 2 === 0

          return (
            <div className={clsx(!isRight && 'lg:-mt-[350px] xl:-mt-[400px] sm:-mt-[250px]')} key={index}>
              {member?.featuredImage?.node ? (
                <LossePlaatjie
                  maxWidth={360}
                  className={clsx('rounded-2xl lg:w-[360px] md:w-[275px] w-[235px] aspect-square', isRight && 'ml-auto')}
                  src={member?.featuredImage?.node}
                />
              ) : (
                <div className="rounded-2xl lg:w-[360px] md:w-[275px] w-[235px] aspect-square bg-transparent" />
              )}

              <div
                className={clsx(
                  'bg-glass-gradient md:p-8 p-6 rounded-2xl -mt-10',
                  isRight ? 'xl:mr-20 lg:mr-10 mr-6' : 'xl:ml-20 lg:ml-10 ml-6'
                )}
              >
                <Content className="lg:children-h3:text-3xl children-h3:text-2xl">{`<h3 class="!mb-2">${member?.title}</h3>
                  <div class="uppercase lg:children-p:text-2xl children-p:text-xl !md:mb-10 mb-6">${member?.info?.title}</div>
                  ${member?.info?.description}
                `}</Content>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}
