export default function Linkedin({ className }: { className?: string }) {
  return (
    <svg className={className ?? undefined} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.757202 21.4802H6.22846V7.09277H0.757202V21.4802ZM1.99531 8.33088H4.99035V20.242H1.99531V8.33088Z" fill="#ADA8F3" />
      <path
        d="M3.49285 0.52002C1.80658 0.52002 0.434753 1.89189 0.434753 3.57811C0.434753 5.26434 1.80658 6.63625 3.49285 6.63625C5.17911 6.63625 6.55098 5.26438 6.55098 3.57811C6.55098 1.89185 5.17907 0.52002 3.49285 0.52002ZM1.67286 3.57811C1.67286 2.57458 2.48928 1.75813 3.49285 1.75813C4.49642 1.75813 5.31287 2.57454 5.31287 3.57811C5.31287 4.58169 4.49642 5.39814 3.49285 5.39814C2.48928 5.39814 1.67286 4.58164 1.67286 3.57811Z"
        fill="#ADA8F3"
      />
      <path
        d="M21.5519 11.6102C21.3572 8.80984 19.0213 6.65186 16.1253 6.65186C14.83 6.65186 13.6072 7.1022 12.6437 7.90128V7.09238H7.40112V21.4798H12.8724V13.611C12.8724 12.7242 13.5939 12.0027 14.4807 12.0027C15.3675 12.0027 16.089 12.7242 16.089 13.6114L16.0943 21.4798H21.5652V11.6235L21.5519 11.6102ZM11.6343 20.2417H8.63923V8.33053H11.4055V10.0988L12.3962 10.1045L12.5803 9.81878C13.3583 8.611 14.6835 7.89001 16.1253 7.89001C18.4297 7.89001 20.2748 9.64821 20.3258 11.8926L20.327 20.2417H17.3316L17.3271 13.611C17.3271 12.0415 16.0502 10.7646 14.4807 10.7646C12.9112 10.7646 11.6343 12.0415 11.6343 13.611V20.2417Z"
        fill="#ADA8F3"
      />
    </svg>
  )
}
