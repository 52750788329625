import { LosseBlogBink, type LosseBlogBinkReadableParams } from '@ubo/losse-sjedel'
import type { ContentNode, Maybe, Page_Flexcontent_Flex_Posts, WpPageInfo } from '~/graphql/types'
import Post from './post-types/Post'
import Pagination from './Pagination'
import Search from './Search'
import Filters from './Filters'

export const READABLE_PARAMS: LosseBlogBinkReadableParams = {
  filters: 'onderwerp',
  direction: 'volgorde',
  sort: 'sortering',
  cursor: 'pagina',
  query: 'zoekterm'
}

export default function PostsBlog({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="PostsBlog" className="section relative -mt-40 lg:-mt-[250px] pt-0 z-20">
      <div className="absolute bg-site-light-50 left-0 w-full top-[200px] bottom-0" />
      <div className="max-w-screen-2xl mx-auto relative z-10">
        <img className="absolute top-0 -left-[5%] w-[110%]" src="/static/overview-cubes.png" alt="" />
        <div className="relative container z-10">
          <LosseBlogBink pageInfo={fields.posts?.pageInfo as Maybe<WpPageInfo> | undefined} readableParams={READABLE_PARAMS}>
            <Overview fields={fields} />
          </LosseBlogBink>
        </div>
      </div>
    </section>
  )
}

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Posts) => {
  const items = fields.posts?.edges as Edge[]

  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Post: Post
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const postTypeName = getPostTypeName(fields)

  return (
    <div className="max-lg:container">
      <div className="bg-gradient rounded-2xl px-6 lg:px-12 pt-10 lg:pt-20 pb-32 lg:pb-40">
        <div className="xl:flex flex-col md:flex-row items-center justify-between md:pr-24">
          <Search />
          <div className="max-xl:mt-4">
            <Filters filters={fields.filters} />
          </div>
        </div>
      </div>
      <div className="lg:mx-8 max-sm:-mx-4">
        <div className="grid grid-cols-6 gap-2 sm:gap-4 md:gap-7 lg:gap-14 -mt-24 ">
          {fields.posts?.edges?.map((edge) => {
            if (!edge || !edge.node) return null

            const Component = PostTypes[postTypeName] || PostTypes.Post

            return (
              <div key={edge.node.uri} className="col-span-3 lg:col-span-2">
                <Component data={edge.node} />
              </div>
            )
          })}
        </div>
        <Pagination />
      </div>
    </div>
  )
}
