export default function Rainbow({ className }: { className?: string }) {
  return (
    <div
      className={className ?? undefined}
      style={{
        width: '200%',
        maxWidth: '2591px',
        rotate: '20deg'
      }}
    >
      <div className="relative w-full h-full">
        <div
          className="absolute top-0 left-0 w-full h-full"
          style={{
            borderRadius: '2591px',
            height: 218,
            opacity: 0.5,
            background: ' radial-gradient(50% 50.00% at 50% 50.00%, #FFF 0%, rgba(185, 232, 255, 0.00) 100%)'
          }}
        />
        <div
          className="absolute top-0 left-0 w-full h-full"
          style={{
            borderRadius: '2591px',
            height: 134,
            opacity: 0.5,
            background: 'radial-gradient(50% 50.00% at 50% 50.00%, #B9E8FF 0%, rgba(185, 232, 255, 0.00) 100%)'
          }}
        />
      </div>
    </div>
  )
}
