import { useLoaderData, useLosseLanguage } from '@ubo/losse-sjedel'
import { LosseLink, type LoaderData } from '@ubo/losse-sjedel'
import type { Component_Footer } from '~/graphql/types'
import useGeneralInfo from '~/hooks/useGeneralInfo'
import Marker from '../elements/svg/Marker'
import Envelope from '../elements/svg/Envelope'
import Phone from '../elements/svg/Phone'
import Facebook from '../elements/svg/Facebook'
import Linkedin from '../elements/svg/Linkedin'
import Instagram from '../elements/svg/Instagram'
import Youtube from '../elements/svg/Youtube'
import A from '../elements/svg/A'
import Content from '../elements/Content'
import Rainbow from '../elements/Rainbow'
import X from '../elements/svg/X'

export interface FooterLoaderData extends Omit<LoaderData, 'footer'> {
  footer: Component_Footer
}

export default function Footer() {
  const { footer } = useLoaderData<FooterLoaderData>()
  const [, locale] = useLosseLanguage()

  const { general, socials } = useGeneralInfo()

  return (
    <footer data-component="Footer" className="relative bg-site-light text-site-base pt-8 lg:pt-20 pb-10 overflow-hidden">
      <A className="absolute -top-32 -right-12 opacity-50 max-w-full" />
      <Rainbow className="absolute top-0 -left-1/2" />
      <div className="relative container py-5">
        <div className="flex sm:justify-center lg:hidden">
          <LosseLink to="/">
            <svg width="170" height="62" viewBox="0 0 170 62" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20.3922 0L-6.10352e-05 35.1906H8.79213V35.1559H9.5292C12.7056 33.5421 18.1635 31.4078 24.7795 31.4078C31.3956 31.4078 37.0289 33.5248 40.4686 35.1559H40.7494V35.1906H49.5591L29.1844 0H20.3922ZM32.589 21.1178L35.274 25.7509C32.1502 24.9353 28.6053 24.3454 24.7971 24.3454C20.9889 24.3454 17.3211 24.9527 14.2324 25.803L16.9701 21.1005H16.9526L17.5668 20.094L24.7795 6.57655L32.0449 20.094L32.6065 21.1005L32.589 21.1178Z"
                fill="#44277C"
              />
              <path d="M59.7377 0H52.1213V35.1906H89.1854V28.1629H59.7377V0Z" fill="#44277C" />
              <path d="M125.793 0H84.6051V7.04506H101.4V35.1906H109.016V7.04506H125.793V0Z" fill="#44277C" />
              <path
                d="M129.215 35.1906V35.1559H129.584C132.76 33.5421 138.218 31.4078 144.834 31.4078C151.45 31.4078 157.084 33.5248 160.523 35.1559H161.173V35.1906H169.982L149.608 0H140.815L120.423 35.1906H129.215ZM137.955 20.1114L145.168 6.5939L152.433 20.1114L152.995 21.1178L155.732 25.8724C152.503 24.9874 148.8 24.3627 144.817 24.3627C141.096 24.3627 137.674 24.918 134.691 25.6989L137.358 21.1178H137.341L137.955 20.1114Z"
                fill="#44277C"
              />
              <path d="M124.179 44.3179H120.423V61.6355H124.179V44.3179Z" fill="#44277C" />
              <path
                d="M139.587 47.4067C142.518 47.4067 145.115 47.9447 147.642 48.6735L148.485 45.3418C147.361 44.9774 144.238 43.9536 139.587 43.9536C130.479 43.9536 126.671 47.8405 126.671 52.9768C126.671 58.1131 130.479 62.0001 139.587 62.0001C144.238 62.0001 147.361 60.9936 148.485 60.6119L147.642 57.2802C145.115 57.9917 142.518 58.547 139.587 58.547C132.988 58.547 130.514 56.2391 130.514 52.9768C130.514 49.7146 132.988 47.4067 139.587 47.4067Z"
                fill="#44277C"
              />
              <path d="M149.748 47.7883H157.996V61.6355H161.752V47.7883H170V44.3179H149.748V47.7883Z" fill="#44277C" />
            </svg>
          </LosseLink>
        </div>
        <div className="flex flex-col lg:flex-row lg:items-center">
          <div className="w-full lg:w-3/5 grid grid-cols-5 gap-x-7 lg:gap-x-32">
            <div className="col-span-5 sm:col-span-2 max-sm:items-start max-md:flex max-md:flex-col max-md:items-center">
              <LosseLink to="/" className="justify-center md:justify-start mb-6 lg:mb-12 hidden lg:flex">
                <svg width="170" height="62" viewBox="0 0 170 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20.3922 0L-6.10352e-05 35.1906H8.79213V35.1559H9.5292C12.7056 33.5421 18.1635 31.4078 24.7795 31.4078C31.3956 31.4078 37.0289 33.5248 40.4686 35.1559H40.7494V35.1906H49.5591L29.1844 0H20.3922ZM32.589 21.1178L35.274 25.7509C32.1502 24.9353 28.6053 24.3454 24.7971 24.3454C20.9889 24.3454 17.3211 24.9527 14.2324 25.803L16.9701 21.1005H16.9526L17.5668 20.094L24.7795 6.57655L32.0449 20.094L32.6065 21.1005L32.589 21.1178Z"
                    fill="#44277C"
                  />
                  <path d="M59.7377 0H52.1213V35.1906H89.1854V28.1629H59.7377V0Z" fill="#44277C" />
                  <path d="M125.793 0H84.6051V7.04506H101.4V35.1906H109.016V7.04506H125.793V0Z" fill="#44277C" />
                  <path
                    d="M129.215 35.1906V35.1559H129.584C132.76 33.5421 138.218 31.4078 144.834 31.4078C151.45 31.4078 157.084 33.5248 160.523 35.1559H161.173V35.1906H169.982L149.608 0H140.815L120.423 35.1906H129.215ZM137.955 20.1114L145.168 6.5939L152.433 20.1114L152.995 21.1178L155.732 25.8724C152.503 24.9874 148.8 24.3627 144.817 24.3627C141.096 24.3627 137.674 24.918 134.691 25.6989L137.358 21.1178H137.341L137.955 20.1114Z"
                    fill="#44277C"
                  />
                  <path d="M124.179 44.3179H120.423V61.6355H124.179V44.3179Z" fill="#44277C" />
                  <path
                    d="M139.587 47.4067C142.518 47.4067 145.115 47.9447 147.642 48.6735L148.485 45.3418C147.361 44.9774 144.238 43.9536 139.587 43.9536C130.479 43.9536 126.671 47.8405 126.671 52.9768C126.671 58.1131 130.479 62.0001 139.587 62.0001C144.238 62.0001 147.361 60.9936 148.485 60.6119L147.642 57.2802C145.115 57.9917 142.518 58.547 139.587 58.547C132.988 58.547 130.514 56.2391 130.514 52.9768C130.514 49.7146 132.988 47.4067 139.587 47.4067Z"
                    fill="#44277C"
                  />
                  <path d="M149.748 47.7883H157.996V61.6355H161.752V47.7883H170V44.3179H149.748V47.7883Z" fill="#44277C" />
                </svg>
              </LosseLink>
              <ul className="sm:mt-8 [&_li>a]:flex [&_li>a]:items-center">
                <li className="mb-1">
                  <LosseLink to={general.address.url} target={general.address.target}>
                    <div className="rounded-lg border-2 border-site-base-200 p-2 mr-3">
                      <Marker />
                    </div>
                    <span>{general.address.title}</span>
                  </LosseLink>
                </li>
                <li className="mb-2">
                  <LosseLink to={`mailto:${general.email}`}>
                    <div className="rounded-lg border-2 border-site-base-200 p-2 mr-3">
                      <Envelope />
                    </div>
                    <span>{general.email}</span>
                  </LosseLink>
                </li>
                <li className="mb-2">
                  <LosseLink to={`tel:${general.phone}`}>
                    <div className="rounded-lg border-2 border-site-base-200 p-2 mr-3">
                      <Phone />
                    </div>
                    <span>{general.phone}</span>
                  </LosseLink>
                </li>
              </ul>
            </div>
            <div className="col-span-5 sm:col-span-3 order-first lg:order-last max-sm:items-start max-md:flex max-md:flex-col max-md:items-center max-lg:mb-8">
              <div className="grid grid-cols-2 gap-x-7">
                <div className="col-span-1">
                  <div className="lg:mt-8 [&_li]:py-1">
                    <span className="inline-block font-semibold text-lg mb-2">{footer.menuFooter.title}</span>
                    <ul>
                      {footer.menuFooter.columnLeft.map((edge) => {
                        return (
                          <li key={edge.link.title}>
                            <LosseLink to={edge.link.url}>{edge.link.title}</LosseLink>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="lg:mt-8 [&_li]:py-1">
                    <span className="inline-block font-semibold text-lg mb-2 opacity-0">{footer.menuFooter.title}</span>
                    <ul>
                      {footer.menuFooter.columnRight.map((edge) => {
                        return (
                          <li key={edge.link.title}>
                            <LosseLink to={edge.link.url}>{edge.link.title}</LosseLink>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 sm:mt-0 w-full lg:w-2/5 flex flex-col items-center max-lg:justify-end">
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-1 rounded-lg bg-white flex justify-center items-center">
                <LosseLink to={socials.facebook} target="_blank" className="p-3 hover:scale-125 transition-transform">
                  <Facebook />
                </LosseLink>
              </div>
              <div className="col-span-1 rounded-lg bg-white flex justify-center items-center">
                <LosseLink
                  to={`${socials.linkedin}${locale === 'en_US' ? '?locale=en_US' : ''}`}
                  target="_blank"
                  className="p-3 hover:scale-125 transition-transform"
                >
                  <Linkedin />
                </LosseLink>
              </div>
              <div className="col-span-1 rounded-lg bg-white flex justify-center items-center">
                <LosseLink to={socials.instagram} target="_blank" className="p-3 hover:scale-125 transition-transform">
                  <Instagram />
                </LosseLink>
              </div>
              <div className="col-span-1 rounded-lg bg-white flex justify-center items-center">
                <LosseLink to={socials.youtube} target="_blank" className="p-3 hover:scale-125 transition-transform">
                  <Youtube />
                </LosseLink>
              </div>
              <div className="col-span-1 rounded-lg bg-white flex justify-center items-center">
                <LosseLink to={`mailto:${general.email}`} target="_blank" className="p-3 hover:scale-125 transition-transform">
                  <Envelope className="children:fill-site-base-200 w-[21px] h-auto" />
                </LosseLink>
              </div>
              <div className="col-span-1 rounded-lg bg-white flex justify-center items-center">
                <LosseLink to={socials.twitter} target="_blank" className="p-3 hover:scale-125 transition-transform">
                  <X />
                </LosseLink>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start">
          <div className="lg:mr-12 xl:mr-28 lg:order-last mt-7 lg:mt-0">
            <Content>{footer.bottomFooter.content}</Content>
          </div>
          <ul className="lg:order-first relative flex flex-col items-center max-sm:[&_li]:text-sm sm:flex-row mt-8 lg:mt-20 max-lg:[&_li]:after:hidden last-of-type:[&_li]:after:hidden [&_li]:relative [&_li]:after:top-[15%] [&_li]:after:h-3/4 [&_li]:after:absolute [&_li]:after:right-0 [&_li]:after:w-[1px] [&_li]:after:bg-site-base">
            <li className="pr-2 ml-2">
              <span>{new Date().getFullYear()}</span>
            </li>
            {footer.bottomFooter.links &&
              footer.bottomFooter.links.map((edge, i) => {
                return (
                  <li key={edge.link.url} className="pr-2 ml-2">
                    <LosseLink src={edge?.link} />
                  </li>
                )
              })}
            <li className="pr-2 ml-2">
              <button
                type="button"
                onClick={() => {
                  // @ts-ignore
                  if (typeof Cookiebot !== 'undefined') Cookiebot.show()
                }}
                className="hover:underline"
              >
                {locale === 'nl_NL' ? 'Cookieverklaring' : 'Cookie statement'}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}
