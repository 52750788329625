export default function Facebook({ className }: { className?: string }) {
  return (
    <svg className={className ?? undefined} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8901 21.8593H9.51177C8.94748 21.8593 8.48845 21.4011 8.48845 20.8379V13.2325H6.51746C5.95317 13.2325 5.49414 12.7742 5.49414 12.2112V8.95223C5.49414 8.38902 5.95317 7.93087 6.51746 7.93087H8.48845V6.29892C8.48845 4.68078 8.99753 3.30409 9.96049 2.31791C10.9278 1.32724 12.2796 0.803711 13.8698 0.803711L16.4463 0.807888C17.0096 0.808851 17.4678 1.267 17.4678 1.82924V4.85508C17.4678 5.41829 17.009 5.87643 16.4448 5.87643L14.7101 5.87708C14.1811 5.87708 14.0464 5.98294 14.0176 6.01539C13.9701 6.0692 13.9136 6.22133 13.9136 6.64141V7.93071H16.3145C16.4952 7.93071 16.6703 7.97521 16.8208 8.05906C17.1455 8.24011 17.3473 8.58243 17.3473 8.95239L17.346 12.2113C17.346 12.7742 16.887 13.2323 16.3227 13.2323H13.9136V20.8379C13.9136 21.4011 13.4544 21.8593 12.8901 21.8593ZM9.72519 20.6249H12.6767V12.6799C12.6767 12.3038 12.9833 11.998 13.3599 11.998H16.1093L16.1104 9.16524H13.3598C12.9831 9.16524 12.6767 8.85938 12.6767 8.48332V6.64141C12.6767 6.15916 12.7258 5.61073 13.0905 5.19853C13.5312 4.70022 14.2257 4.64271 14.7098 4.64271L16.2311 4.64207V2.04193L13.8688 2.03808C11.3133 2.03808 9.72519 3.67083 9.72519 6.29892V8.48332C9.72519 8.85922 9.41874 9.16524 9.04212 9.16524H6.73088V11.998H9.04212C9.41874 11.998 9.72519 12.3038 9.72519 12.6799V20.6249Z"
        fill="#ADA8F3"
      />
    </svg>
  )
}
