/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, type PropsWithChildren, useState } from 'react'
import { LosseLayout } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header/Header'
import { AnimatePresence, motion } from 'framer-motion'

export default function Layout({ children }: PropsWithChildren<object>) {
  const [showToTop, setShowToTop] = useState(false)

  function onScroll() {
    setShowToTop(window.scrollY > 100)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <LosseLayout>
      <Header />
      <main className="min-h-screen-dynamic overflow-hidden">{children}</main>
      <Footer />
      <AnimatePresence>
        {showToTop && (
          <motion.button
            type="button"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })
            }}
            animate={{
              opacity: 1,
              x: 0
            }}
            initial={{
              opacity: 0,
              x: 100
            }}
            exit={{
              opacity: 0,
              x: 100
            }}
            transition={{
              duration: 0.2
            }}
            className="fixed right-0 rounded-l-md top-2/3 bg-gradient-to-r bg-gradient p-2 lg:p-4 z-50 text-white shadow"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lg:w-6 lg:h-6 h-5 w-5"
            >
              <line x1="12" y1="19" x2="12" y2="5"></line>
              <polyline points="5 12 12 5 19 12"></polyline>
            </svg>
          </motion.button>
        )}
      </AnimatePresence>
    </LosseLayout>
  )
}
