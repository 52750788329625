import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Accordion } from '~/graphql/types'
import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { motion, useInView, useScroll } from 'framer-motion'
import { LosseLink, useLocation } from '@ubo/losse-sjedel'

export default function AccordionDefault({ fields }: { fields: Page_Flexcontent_Flex_Accordion }) {
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const [scrollProgress, setScrollProgress] = useState(0)
  const { scrollYProgress } = useScroll()
  const location = useLocation()

  const sectionRef = useRef(null)
  const sectionInView = useInView(sectionRef, {
    once: true,
    margin: '-50%'
  })

  useEffect(() => {
    const handleScroll = () => {
      const scrollProgress = scrollYProgress.get()
      setScrollProgress(scrollProgress)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollYProgress])

  return (
    <section ref={sectionRef} className="relative" data-component="AccordionDefault">
      <div className={clsx(location.pathname === '/' ? 'bg-transparent' : 'bg-site-light', 'absolute -z-10 inset-0 w-full h-full')}></div>
      <div className="container">
        <div className="flex justify-end">
          <div className="relative z-10 -mr-7 sm:-mr-11 lg:-mr-32 lg:w-4/5 rounded-l-[2rem] sm:rounded-[2rem] pb-10 lg:pb-10 bg-gradient-to-r bg-gradient text-white max-lg:overflow-hidden">
            <motion.div
              initial={{ opacity: 0, y: '-100%' }}
              animate={sectionInView ? { opacity: 1, y: 0 } : { opacity: 0, y: '-100%' }}
              style={{ translateX: -(scrollProgress * 100) }}
              transition={{
                delay: 0.25
              }}
              className="absolute h-fit lg:-left-72 -top-16 lg:top-0"
            >
              <img src="/static/accordion-cube-bottom.png" alt="" className="max-lg:opacity-5" />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: '-100%' }}
              animate={sectionInView ? { opacity: 1, y: 0 } : { opacity: 0, y: '-100%' }}
              style={{ translateX: scrollProgress * 100 }}
              transition={{
                delay: 0.5
              }}
              className="absolute h-fit lg:-left-72 -top-16 lg:top-0"
            >
              <img src="/static/accordion-cube-center.png" alt="" className="max-lg:opacity-5" />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: '-100%' }}
              animate={sectionInView ? { opacity: 1, y: 0 } : { opacity: 0, y: '-100%' }}
              style={{ translateX: -(scrollProgress * 100) }}
              transition={{
                delay: 0.75
              }}
              className="absolute h-fit lg:-left-72 -top-16 lg:top-0"
            >
              <img src="/static/accordion-cube-top.png" alt="" className="max-lg:opacity-5" />
            </motion.div>
            <div className="relative px-8 lg:px-28 lg:py-10">
              {fields.items.map((edge, i) => {
                const active = currentItemIndex === i

                return (
                  <div key={edge.title} className="pt-3 border-b border-white">
                    <button onClick={() => setCurrentItemIndex(i)} className="group py-9 w-full flex justify-between items-center">
                      <h2 className="uppercase font-semibold text-xl lg:text-5xl group-hover:underline">{edge.title}</h2>
                      <svg
                        className={clsx(active ? 'rotate-90' : 'rotate-0', 'transition-transform')}
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="27"
                        viewBox="0 0 14 27"
                        fill="none"
                      >
                        <path
                          d="M2.72126 26.3549L0.0168457 23.6448L10.1541 13.4999L0.0168457 3.35494L2.72892 0.644775L12.8585 10.7897C13.5771 11.5085 13.9808 12.4834 13.9808 13.4999C13.9808 14.5163 13.5771 15.4912 12.8585 16.21L2.72126 26.3549Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={active ? { height: 'auto', opacity: 1 } : { height: 0, opacity: 0 }}
                      className="overflow-hidden"
                    >
                      <Content className="children:!text-white pb-8">{edge.description}</Content>
                    </motion.div>
                  </div>
                )
              })}
              <LosseLink to={fields.link.url} className="btn btn-white mt-14">
                {fields.link.title}
              </LosseLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
