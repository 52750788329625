import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LossePlaatjie, useLocation } from '@ubo/losse-sjedel'
import A from '~/components/elements/svg/A'
import Button from '~/components/elements/Button'
import Rainbow from '~/components/elements/Rainbow'
import clsx from 'clsx'

export default function BannerLanding({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const { pathname } = useLocation()
  const isLanding = pathname === '/' || pathname === '/en/'

  return (
    <section
      data-component="BannerLanding"
      className="z-20 bg-gradient-to-bl from-site-light to-site-light max-lg:overflow-hidden max-lg:!pt-32 max-lg:pb-8 lg!py-32"
    >
      <div className={clsx('container', isLanding && 'lg:pt-16')}>
        <Rainbow className="absolute -right-1/3 top-0 " />
        <A className="absolute top-14 lg:top-28 -left-12 opacity-50 max-w-full h-[80%] lg:h-auto" />
        <div className="relative grid grid-cols-2 lg:gap-x-8 items-start lg:items-center">
          <div className={clsx(isLanding ? 'col-span-1' : 'col-span-2', 'relative sm:col-span-1 z-10 flex items-center h-full')}>
            <div className="lg:py-10">
              <Content className="children-strong:font-outline-site-base children-h1:text-2xl xs:children-h1:text-3xl md:children-h1:text-5xl xl:children-h1:text-7xl">
                {fields.content}
              </Content>
              {fields.links && fields.links.length > 0 && (
                <div className="mt-12 max-lg:hidden">
                  {fields.links.map((edge) => {
                    return (
                      <Button key={edge.link.title} to={edge.link.url} className="btn-light">
                        {edge.link.title}
                      </Button>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
          <div className={clsx(isLanding ? 'col-span-1' : 'col-span-2', 'sm:col-span-1')}>
            <div className="max-lg:-mt-8 -mr-8 lg:-mr-16">
              <div className="relative">
                {fields.imageShadow && (
                  <div className="absolute -left-[33%] -top-[33%] w-[175%] h-[175%] bg-gradient-radial from-site-base-400 via-transparent pointer-events-none" />
                )}

                <LossePlaatjie
                  loading="eager"
                  maxwidth={1000}
                  src={fields.image}
                  className={clsx(
                    fields.imageOverflow ? 'lg:scale-150 lg:-top-[100px]' : 'lg:hover:scale-110 max-lg:scale-110',
                    fields.imageOverflow && isLanding && '-top-28',
                    'relative transition-transform max-sm:max-w-[70vw] max-sm:mt-8 max-sm:mx-auto object-contain'
                  )}
                />
              </div>
            </div>
            {fields.cta?.link?.url && (
              <div className="fixed right-6 bottom-6 lg:flex lg:justify-end mt-28 hidden">
                <Button to={fields.cta.link.url} className="btn-white shadow">
                  <span>{fields.cta.link.title}</span>
                  {fields.cta.image ? (
                    <LossePlaatjie maxwidth={60} src={fields.cta.image} className="rounded-full" />
                  ) : (
                    <div className="rounded-full w-[40px] h-[40px] ml-2 bg-site-base flex items-center justify-center -mr-4">
                      <A className="w-[70%] h-[70%] -mt-[4px]" />
                    </div>
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
        {fields.links && fields.links.length > 0 && (
          <div className="relative mt-6 lg:hidden">
            {fields.links.map((edge) => {
              return (
                <Button key={edge.link.title} to={edge.link.url} className="btn-light">
                  {edge.link.title}
                </Button>
              )
            })}
          </div>
        )}
      </div>
    </section>
  )
}
