import { useLosseBlogBink } from '@ubo/losse-sjedel'
import clsx from 'clsx'

export default function Pagination() {
  const { hasPrevPage, hasNextPage, prevPage, nextPage } = useLosseBlogBink()

  return (
    <div className="mt-10 flex justify-end max-sm:mr-4">
      <button
        type="button"
        className={clsx(
          'text-white ml-3 w-8 h-8 lg:w-10 lg:h-10 rounded-full flex items-center justify-center bg-site-base-100  button-base-ring border-site-base disabled:opacity-25',
          hasPrevPage &&
            'bg-site-base-300 hover:bg-site-base hover:border-site-light hover:text-white focus:ring-offset-white focus:ring-blue'
        )}
        disabled={!hasPrevPage}
        onClick={prevPage}
      >
        <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
          <path
            d="M1.66694e-06 5.5C-0.000374548 5.28465 0.041951 5.07135 0.124546 4.87236C0.207141 4.67336 0.328377 4.4926 0.481285 4.34046L4.83669 -5.08498e-08L6 1.15953L1.64541 5.5L6 9.84046L4.83669 11L0.482108 6.65953C0.329051 6.50746 0.207664 6.32673 0.124926 6.12774C0.0421889 5.92874 -0.000266325 5.71541 1.66694e-06 5.5Z"
            fill="currentColor"
          />
        </svg>
      </button>
      <button
        type="button"
        className={clsx(
          'text-white ml-2 lg:ml-3 w-8 h-8 lg:w-10 lg:h-10 rounded-full flex items-center justify-center bg-site-base-100 button-base-ring border-site-base disabled:opacity-25',
          hasNextPage &&
            'bg-site-base-300 hover:bg-site-base hover:border-site-base hover:text-white focus:ring-offset-white focus:ring-blue'
        )}
        disabled={!hasNextPage}
        onClick={nextPage}
      >
        <svg width="3" height="6" viewBox="0 0 3 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
          <path
            d="M3 3C3.00019 3.11746 2.97902 3.23381 2.93773 3.34235C2.89643 3.45089 2.83581 3.54949 2.75936 3.63247L0.581654 6L-2.76463e-08 5.36753L2.17729 3L-2.34622e-07 0.632475L0.581653 1.79439e-08L2.75895 2.36753C2.83547 2.45047 2.89617 2.54905 2.93754 2.6576C2.97891 2.76614 3.00013 2.88251 3 3Z"
            fill="currentColor"
          />
        </svg>
      </button>
    </div>
  )
}
