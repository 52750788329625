import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentUsps({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentUsps" className="section bg-site-light relative">
      <div className="bg-white 2xl:block hidden absolute left-0 top-0 bottom-0 h-[70%] my-auto w-[50px] rounded-r-lg rounded-l-none shadow"></div>
      <div className="container relative z-10">
        <img
          className="absolute lg:-right-32 -right-20 lg:-top-44 -top-20 lg:w-[800px] w-[600px] h-auto object-contain"
          src="/static/usps-bg.png"
          alt="Background cubes"
        />

        {fields.description && (
          <Content className="content children-strong:font-outline-site-base xl:children-strong:text-6xl lg:children-strong:text-5xl md:children-strong:text-4xl children-strong:text-3xl lg:children-h2:text-5xl sm:children-h2:text-4xl mb-8 lg:mb-16">
            {fields?.description}
          </Content>
        )}
        {fields.descriptions && (
          <div className="lg:grid flex flex-col grid-cols-3 xl:gap-12 gap-8">
            {fields.descriptions.map((description, index) => (
              <Content
                key={index}
                className={clsx('content children-p:mb-2', fields?.blocksWithBgGlass && 'bg-glass-gradient rounded-2xl p-6')}
              >
                {description?.description}
              </Content>
            ))}
          </div>
        )}

        {/* {fields.links && (
          <div className="flex items-center gap-2 mt-4">
            {fields.links.map((link, index) => (
              <Button className="odd:btn-default even:btn-light" key={index} to={link?.link?.url || '/'}>
                {link?.link?.title}
              </Button>
            ))}
          </div>
        )} */}
      </div>
    </section>
  )
}
