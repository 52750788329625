import type { LoaderData } from '@ubo/losse-sjedel'
import { Link, LossePlaatjie, useLoaderData, useLosseLanguage } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import Rainbow from '~/components/elements/Rainbow'
import A from '~/components/elements/svg/A'
import ArrowLeft from '~/components/elements/svg/ArrowLeft'
import type { Acf_Link, NodeWithFeaturedImage, Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentBlog({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [, locale] = useLosseLanguage()

  const { page, rest } = useLoaderData<
    LoaderData & {
      page: NodeWithFeaturedImage
      rest: {
        BlogInfo: {
          blogInfo: {
            backToOverview: Acf_Link
            underArticle: {
              title: string
              link: Acf_Link
            }
          }
        }
      }
    }
  >()
  const blogInfo = rest.BlogInfo.blogInfo

  const publishedAt = new Date(page.date).toLocaleDateString(locale.replace('_', '-'), { day: '2-digit', month: 'long', year: 'numeric' })

  return (
    <section data-component="ContentBlog" className="section bg-site-light">
      <Rainbow className="absolute -right-1/3 top-0 " />
      <A className="absolute top-14 lg:top-28 -left-12 opacity-50 sm:max-w-full max-w-sm h-auto" />
      <img className="absolute -right-[10vw] -bottom-[10vw]" src="/static/usps-bg.png" alt="" />

      <div className="container py-20 relative">
        <Link
          to={blogInfo.backToOverview.url}
          target={blogInfo.backToOverview.target}
          className="flex items-center gap-x-4 xl:pl-20 sm:-mb-12 mb-6"
        >
          <ArrowLeft />
          <Content className="content">{`<p>${blogInfo.backToOverview.title}</p>`}</Content>
        </Link>
        <div className="md:grid flex flex-col-reverse grid-cols-6">
          <div className="xl:ml-20 md:mt-20 sm:-mt-10 mt-5 col-span-5">
            <div className="bg-white xl:px-32 lg:px-20 sm:px-16 px-6 sm:py-14 py-6 rounded-2xl shadow">
              <Content className="content children-p:font-bold sm:mb-6 mb-4">{`<p>${publishedAt}</p>`}</Content>
              <Content className="content lg:children-h1:text-5xl sm:children-h1:text-3xl children-h1:text-xl children-p:leading-tight max-w-full children-h2:mb-1 sm:children-h2:text-2xl children-h2:text-xl children-h2:font-sans sm:children-h3:text-xl children-h3:text-lg children-h3:font-sans children-h3:font-medium children-h3:mb-1">
                {`<h1>${page.title}</h1>`}
              </Content>

              {fields?.introduction && (
                <Content className="content lg:children-h1:text-5xl sm:children-h1:text-3xl children-h1:text-xl children-p:leading-tight max-w-full children-h2:mb-1 sm:children-h2:text-2xl children-h2:text-xl children-h2:font-sans sm:children-h3:text-xl children-h3:text-lg children-h3:font-sans children-h3:font-medium children-h3:mb-1">
                  {fields?.introduction || ''}
                </Content>
              )}

              {fields?.link && fields?.link?.url && (
                <div className="flex justify-center my-6 lg:my-12"><Button to={fields?.link?.url} className="btn-default">{fields?.link?.title}</Button></div>
              )}

              <Content className="content children-p:leading-tight max-w-full children-h2:mb-1 sm:children-h2:text-2xl children-h2:text-xl children-h2:font-sans sm:children-h3:text-xl children-h3:text-lg children-h3:font-sans children-h3:font-medium children-h3:mb-1">
                {fields?.description || ''}
              </Content>
            </div>

            <div className="flex flex-col py-10">
              <Content className="content max-w-full mb-4">{blogInfo.underArticle.title}</Content>
              <Button to={blogInfo.underArticle.link.url} target={blogInfo.underArticle.link.target} className="btn-light mx-auto">
                {blogInfo.underArticle.link.title}
              </Button>
            </div>
          </div>

          <div className="md:-ml-40 col-span-1">
            <LossePlaatjie
              src={page?.featuredImage?.node}
              maxWidth={500}
              className="lg:w-full sm:w-[300px] w-full sm:ml-auto mx-auto h-auto object-cover rounded-2xl z-10 relative sm:-mr-10"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
