import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentImage" className="section bg-site-light">
      <div className="container">
        <div className="grid grid-cols-2 gap-x-12 xl:items-end">
          <div className="col-span-2 sm:col-span-1 flex items-center h-full">
            <div className="relative max-lg:max-w-[75vw] max-lg:mx-auto">
              <div className="absolute -left-[40%] -top-[33%] w-[175%] h-[175%] bg-gradient-radial from-site-base-400 via-transparent pointer-events-none" />
              <LossePlaatjie src={fields.image} maxWidth={800} className="relative" />
            </div>
          </div>
          <div className={clsx(fields.swap && 'lg:order-first', 'col-span-2 sm:col-span-1', 'flex items-center h-full')}>
            <div>
              {fields.description && (
                <Content className="content xl:children-h2:text-5xl lg:children-h2:mb-8 lg:children-p:mb-6 xl:pr-28">
                  {fields?.description}
                </Content>
              )}
              {fields.links && (
                <div className="flex flex-wrap items-center gap-2 lg:mt-10 mt-6">
                  {fields.links.map((link, index) => (
                    <Button className="odd:btn-default even:btn-light" key={index} to={link?.link?.url || '/'}>
                      {link?.link?.title}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
