import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Rainbow from '~/components/elements/Rainbow'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentTiles({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentTiles" className="section relative">
      <div className="absolute top-0 left-0 h-3/4 w-full bg-gradient-to-t from-white to-site-light -z-20" />
      <div className="relative w-full h-full top-28">
        <div
          className="absolute top-[50px] -left-[50vw] w-[200vw] h-[1000px] -rotate-[15deg] -z-20"
          style={{
            background:
              'linear-gradient(0deg, rgba(68, 39, 124, 0.00) 0%, rgba(68, 39, 124, 0.45) 38.54%, rgba(68, 39, 124, 0.45) 69.79%, rgba(68, 39, 124, 0.00) 100%)'
          }}
        ></div>
        <Rainbow className="absolute top-[200px] -rotate-[37.5deg] -z-10" />
      </div>
      <div className="relative container">
        <div className="absolute -left-[200px] -top-[375px] bg-gradient-radial from-site-light via-transparent w-[900px] h-[900px]"></div>
        <div className="relative grid grid-cols-2 gap-8">
          {fields.description && (
            <div className="col-span-2 lg:col-span-1">
              <Content
                className="content lg:pr-24
              xl:children-strong:text-7xl lg:children-strong:text-6xl md:children-strong:text-5xl children-strong:text-3xl xl:children-h2:text-7xl md:children-h2:text-6xl children-h2:text-5xl children-strong:font-outline-site-base
              "
              >
                {fields?.description}
              </Content>
              <img
                src="/static/services-cube.png"
                alt=""
                className="absolute opacity-50 lg:opacity-100 lg:relative mt-12 lg:mt-24 pointer-events-none"
              />
            </div>
          )}
          <div className="col-span-2 lg:col-span-1">
            <div className="relative z-10 grid grid-cols-2 gap-2 lg:gap-8">
              {fields.items.map((edge) => {
                return (
                  <div key={edge.link.title} className="col-span-1">
                    <LosseLink
                      to={edge.link.url}
                      className="group block h-full bg-glass rounded-2xl overflow-hidden p-3 lg:p-4 xl:p-9 xl:aspect-[9/13] hover:bg-gradient-to-br from-site-base via-site-base/90 hover:!border-site-base"
                    >
                      <div className="bg-white h-[40px] w-[40px] lg:w-[64px] lg:h-[64px] rounded-lg flex justify-center items-center mb-4 lg:mb-5">
                        <LossePlaatjie src={edge.icon} maxwidth={100} className="max-lg:w-[30px] max-lg:h-[30px]" />
                      </div>
                      <h3 className="font-semibold text-site-base group-hover:text-white text-base lg:text-lg xl:text-2xl lg:mb-4">
                        {edge.link.title}
                      </h3>
                      <Content className="group-hover:opacity-100 lg:opacity-0 lg:translate-y-4 group-hover:translate-y-0 transition-all line-clamp-4 lg:line-clamp-5 xl:line-clamp-[7] group-hover:children:text-white max-sm:text-sm">
                        {edge.description}
                      </Content>
                    </LosseLink>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
