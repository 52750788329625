import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import PostsBlog from './PostsBlog'
import Team from './Team'
import Cases from './Cases'
import CasesHighlighted from './CasesHighlighted'

export default function PostsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    blog: PostsBlog,
    team: Team,
    cases: Cases,
    highlighted_cases: CasesHighlighted
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
