import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_CallToAction } from '~/graphql/types'
import Button from '~/components/elements/Button'

export default function CallToActionDefault({ fields }: { fields: Page_Flexcontent_Flex_CallToAction }) {
  return (
    <section className="bg-site-light pt-0 relative" data-component="CallToActionDefault">
      <div className="container flex items-center flex-col gap-y-4">
        <Content className="content">{fields.description}</Content>

        <div className="flex sm:flex-row flex-col gap-4">
          {fields.links?.map((link, index) => (
            <Button className="odd:btn-light even:btn-default" key={index} to={link.link.url} target={link.link.target}>
              {link.link.title}
            </Button>
          ))}
        </div>
      </div>
    </section>
  )
}
