import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ContentDefault from './ContentDefault'
import ContentUsps from './ContentUsps'
import ContentTiles from './ContentTiles'
import ContentIframe from './ContentIframe'
import ContentColumns from './ContentColumns'
import ContentSteps from './ContentSteps'
import ContentVideo from './ContentVideo'
import ContentBlog from './ContentBlog'
import ContentImage from './ContentImage'
import ContentServices from './ContentServices'
import ContentPackages from './ContentPackages'

export default function ContentShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: ContentDefault,
    image: ContentImage,
    usps: ContentUsps,
    tiles: ContentTiles,
    iframe: ContentIframe,
    columns: ContentColumns,
    steps: ContentSteps,
    video: ContentVideo,
    blog: ContentBlog,
    services: ContentServices,
    packages: ContentPackages
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
