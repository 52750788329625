import { type LoaderData, useLoaderData } from '@ubo/losse-sjedel'
import type { Component_Generalinfo } from '~/graphql/types'

interface GeneralInfoLoaderData extends Omit<LoaderData, 'rest'> {
  rest: {
    GeneralInfo: {
      generalInfo: Component_Generalinfo
    }
  }
}

export default function useGeneralInfo(): Component_Generalinfo {
  const { rest } = useLoaderData<GeneralInfoLoaderData>()

  return rest.GeneralInfo.generalInfo
}
