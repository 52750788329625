export default function A({ className }: { className?: string }) {
  return (
    <svg width="839" height="669" viewBox="0 0 839 669" fill="none" xmlns="http://www.w3.org/2000/svg" className={className ?? undefined}>
      <path
        d="M345.479 1.50043L2.32658 667.5H150.37L150.656 666.947H155.368C213.268 636.123 309.608 596.067 419.497 596.067C529.386 596.067 622.622 636.423 676.188 666.947H688.344L688.625 667.5H836.663L493.521 1.50043H345.479ZM297.56 382.07L419.929 126.138L541.446 382.07L551.718 401.101H551.292L597.262 489.981C545.273 473.836 485.262 462.331 419.513 462.331C355.335 462.331 295.595 473.284 243.087 488.786L288.156 401.095L297.57 382.064L297.56 382.07Z"
        fill="white"
        stroke="white"
        strokeWidth="5"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
