import { motion } from 'framer-motion'
import type { Maybe, Component_Header_MenuHeader_Submenu } from '~/graphql/types'
import { LosseLink, LossePlaatjie, losseContentParse, useLosseLayout } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import clsx from 'clsx'

export default function HeaderSubmenu({ fields }: { fields: Maybe<Component_Header_MenuHeader_Submenu>[] }) {
  return (
    <motion.div
      initial={{ opacity: 1, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{
        duration: 0.2
      }}
      className="relative w-full lg:overflow-hidden z-50"
    >
      <div className="max-lg:hidden">
        <HeaderSubmenuGrid fields={fields} />
      </div>
      <div className="lg:hidden">
        <HeaderSubmenuSlider fields={fields} />
      </div>
    </motion.div>
  )
}

// Deze enum bewaakt de leesbaarheid van de code.
enum Service {
  Migratie,
  Beheer,
  Beveiliging,
  ModerneWerkplek
}

function MenuItem(fields: Maybe<Component_Header_MenuHeader_Submenu>[], key: number, orientation: string = 'col') {
  return orientation == 'col' ? (
    <LosseLink
      target="_self"
      to={fields[key].link.url || '/'}
      className=" bg-white p-8 rounded-3xl hover:bg-site-light-100 w-full h-full transition-colors flex flex-col !w-min-[260px]"
    >
      <div className="bg-site-light group-hover:bg-white rounded-lg aspect-square w-16 h-16 flex justify-center items-center mb-5 transition-colors">
        <LossePlaatjie src={fields[key].icon} maxWidth={100} />
      </div>
      <span className="font-headings text-2xl inline-block font-semibold mb-2 min-h-[5ex]">
        {losseContentParse(fields[key]?.link?.title)}
      </span>
      <div className="group-hover:translate-y-2 transition-transform">
        <Content className="text-sm children-p:!leading-6">{fields[key].content}</Content>
      </div>
    </LosseLink>
  ) : (
    <LosseLink
      target="_self"
      to={fields[key].link.url || '/'}
      className=" bg-white p-8 rounded-3xl hover:bg-site-light-100 w-full h-full transition-colors flex flex-row gap-7"
    >
      <div className="bg-site-light group-hover:bg-white rounded-lg aspect-square w-16 h-16 flex justify-center transition-colors my-auto top-0 bottom-0">
        <LossePlaatjie src={fields[key].icon} maxWidth={100} />
      </div>
      <div className="flex flex-col gap-1 justify-center">
        <span className="font-headings text-2xl inline-block font-semibold">{losseContentParse(fields[key]?.link?.title)}</span>
        <div className="group-hover:translate-y-2 transition-transform">
          <Content className="text-sm children-p:!leading-6">{fields[key].content}</Content>
        </div>
      </div>
    </LosseLink>
  )
}

function HeaderSubmenuGrid({ fields }: { fields: Maybe<Component_Header_MenuHeader_Submenu>[] }) {
  const { isSticky } = useLosseLayout()

  return (
    <div className={clsx(isSticky ? 'lg:pt-7' : 'lg:pt-9', 'lg:pb-2 ')}>
      <div className=" rounded-[40px] bg-white shadow-md max-xl:flex max-xl:flex-col xl:grid xl:grid-cols-4 gap-5 p-7">
        <div className=" max-xl:hidden col-span-1 rounded-3xl bg-site-light-bg bg-opacity-50 p-8">{MenuItem(fields, Service.Migratie)}</div>
        <div className=" xl:hidden rounded-3xl bg-site-light-50 p-8">{MenuItem(fields, Service.Migratie, 'row')}</div>
        <div className=" xl:col-span-3 bg-site-light-bg bg-opacity-50 rounded-3xl p-8 grid grid-cols-3 gap-7">
          {MenuItem(fields, Service.ModerneWerkplek)}
          <div className="col-span-2 grid grid-rows-2 gap-7">
            {MenuItem(fields, Service.Beheer, 'row')}
            {MenuItem(fields, Service.Beveiliging, 'row')}
          </div>
        </div>
      </div>
    </div>
  )
}

function HeaderSubmenuSlider({ fields }: { fields: Maybe<Component_Header_MenuHeader_Submenu>[] }) {
  return (
    <Slider
      className="!py-5 sm:!py-12 !pl-4 !pr-8 sm:!pr-4 max-sm:!-mr-8 relative bg-site-light-100 rounded-3xl"
      breakpoints={{
        0: {
          slidesPerView: 1.5,
          spaceBetween: 10
        },
        640: {
          slidesPerView: 2.5,
          spaceBetween: 15
        },
        768: {
          slidesPerView: 3.25,
          spaceBetween: 20
        }
      }}
    >
      {fields.map((edge) => {
        return (
          <Slide key={edge.link.title} className="!h-auto">
            <LosseLink to={edge?.link?.url || '/'} className="group rounded-3xl p-3 flex flex-col bg-white w-full h-full transition-colors">
              <div className="bg-site-light rounded-lg aspect-square w-10 h-10 flex justify-center items-center mb-2 transition-colors">
                <LossePlaatjie src={edge.icon} maxWidth={100} />
              </div>
              <span className="font-headings text-sm inline-block font-semibold">{losseContentParse(edge?.link?.title)}</span>
              <div className="group-hover:translate-y-2 transition-transform">
                <Content className="text-sm">{edge.content}</Content>
              </div>
            </LosseLink>
          </Slide>
        )
      })}
    </Slider>
  )
}
