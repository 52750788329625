export default function Marker({ className }: { className?: string }) {
  return (
    <svg className={className ?? undefined} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.08559 16.7782C8.17796 16.9168 8.33349 17 8.5 17C8.66651 17 8.82204 16.9168 8.91441 16.7782C10.0931 15.0102 11.8292 12.8268 13.039 10.6061C14.0063 8.83054 14.4766 7.31618 14.4766 5.97656C14.4766 2.68109 11.7955 0 8.5 0C5.20452 0 2.52344 2.68109 2.52344 5.97656C2.52344 7.31618 2.99369 8.83054 3.96103 10.6061C5.16993 12.8251 6.90934 15.0139 8.08559 16.7782ZM8.5 0.996094C11.2462 0.996094 13.4805 3.23033 13.4805 5.97656C13.4805 7.14545 13.0499 8.50392 12.1643 10.1296C11.1215 12.0437 9.63336 13.9854 8.5 15.6177C7.36681 13.9856 5.87861 12.0438 4.83574 10.1296C3.95008 8.50392 3.51953 7.14545 3.51953 5.97656C3.51953 3.23033 5.75377 0.996094 8.5 0.996094Z"
        fill="#44277C"
      />
      <path
        d="M8.5 8.96484C10.1477 8.96484 11.4883 7.6243 11.4883 5.97656C11.4883 4.32882 10.1477 2.98828 8.5 2.98828C6.85226 2.98828 5.51172 4.32882 5.51172 5.97656C5.51172 7.6243 6.85226 8.96484 8.5 8.96484ZM8.5 3.98438C9.59849 3.98438 10.4922 4.87807 10.4922 5.97656C10.4922 7.07505 9.59849 7.96875 8.5 7.96875C7.40151 7.96875 6.50781 7.07505 6.50781 5.97656C6.50781 4.87807 7.40151 3.98438 8.5 3.98438Z"
        fill="#44277C"
      />
    </svg>
  )
}
