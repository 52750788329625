import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import A from '~/components/elements/svg/A'

export default function FormCallback({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section data-component="FormCallback" className="section bg-site-light relative pb-36 lg:!pt-40">
      <A className="absolute top-14 lg:top-28 -left-12 opacity-50 max-w-full h-auto" />

      <div className="container md:pt-20 pt-16 md:grid flex flex-col grid-cols-12 relative xl:gap-0 lg:gap-20">
        <div className="mb-6 col-span-12 lg:col-span-5">
          <Content className="content xl:children-h1:text-7xl lg:children-h1:text-5xl md:mb-20 mb-6">
            <h1>{fields?.title}</h1>
          </Content>
        </div>
        <div className="col-span-12 lg:col-span-7">
          <Content className="content mb-10">{fields?.description}</Content>
          <div className="mt-5 bg-gradient xl:-ml-10 text-white md:p-10 sm:p-8 p-6 rounded-[25px] lg:py-14 lg:px-16 relative overflow-hidden">
            <Form
              className="form-contact form-grid"
              data={fields.form}
              renderLoading={() => <Loading />}
              generate
              renderStatus={({ content }) => <Content className="content">{content}</Content>}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
